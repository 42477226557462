// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-de-buchung-js": () => import("./../../../src/pages/de/buchung.js" /* webpackChunkName: "component---src-pages-de-buchung-js" */),
  "component---src-pages-de-fotogallerie-js": () => import("./../../../src/pages/de/fotogallerie.js" /* webpackChunkName: "component---src-pages-de-fotogallerie-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-menu-js": () => import("./../../../src/pages/de/menu.js" /* webpackChunkName: "component---src-pages-de-menu-js" */),
  "component---src-pages-en-booking-js": () => import("./../../../src/pages/en/booking.js" /* webpackChunkName: "component---src-pages-en-booking-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-menu-js": () => import("./../../../src/pages/en/menu.js" /* webpackChunkName: "component---src-pages-en-menu-js" */),
  "component---src-pages-en-photogallery-js": () => import("./../../../src/pages/en/photogallery.js" /* webpackChunkName: "component---src-pages-en-photogallery-js" */),
  "component---src-pages-fotogalerie-js": () => import("./../../../src/pages/fotogalerie.js" /* webpackChunkName: "component---src-pages-fotogalerie-js" */),
  "component---src-pages-hr-booking-js": () => import("./../../../src/pages/hr/booking.js" /* webpackChunkName: "component---src-pages-hr-booking-js" */),
  "component---src-pages-hr-index-js": () => import("./../../../src/pages/hr/index.js" /* webpackChunkName: "component---src-pages-hr-index-js" */),
  "component---src-pages-hr-menu-js": () => import("./../../../src/pages/hr/menu.js" /* webpackChunkName: "component---src-pages-hr-menu-js" */),
  "component---src-pages-hr-photogallery-js": () => import("./../../../src/pages/hr/photogallery.js" /* webpackChunkName: "component---src-pages-hr-photogallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-pl-galeria-zdjec-js": () => import("./../../../src/pages/pl/galeria-zdjęc.js" /* webpackChunkName: "component---src-pages-pl-galeria-zdjec-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-menu-js": () => import("./../../../src/pages/pl/menu.js" /* webpackChunkName: "component---src-pages-pl-menu-js" */),
  "component---src-pages-pl-rezerwowac-js": () => import("./../../../src/pages/pl/rezerwowac.js" /* webpackChunkName: "component---src-pages-pl-rezerwowac-js" */),
  "component---src-pages-rezervace-js": () => import("./../../../src/pages/rezervace.js" /* webpackChunkName: "component---src-pages-rezervace-js" */)
}

